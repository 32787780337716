import {CircularProgress} from '@material-ui/core'
import {GraphQLErrors} from 'components/graphql-errors'
import {Page} from 'components/layout/page'
import {useGetAuthUserQuery, UserFragment} from 'generated/graphql'
import {useRouter} from 'next/router'
import ErrorPage from 'pages/_error'
import {useEffect, useState} from 'react'
import * as settings from 'settings'

type UserCheck = (user: UserFragment) => boolean

export const userCheckRequired = (check: UserCheck) => (Component: React.ComponentType) => () => {
    const router = useRouter()
    const [showForbidden, setShowForbidden] = useState(false)
    const {data, loading, error, refetch} = useGetAuthUserQuery()

    useEffect(() => {
        if (!loading && !error) {
            if (!data?.authUser) {
                router.push(`${settings.LOGIN_URL}?next=${router.asPath}`)
            } else if (!check(data.authUser)) {
                // throw new Forbidden()
                setShowForbidden(true)
            }
        }
    }, [data, loading, error])

    if (showForbidden) return <ErrorPage statusCode={403} />
    if (error) return <Page><GraphQLErrors error={error} refetch={refetch} /></Page>
    if (!data?.authUser && loading) return <Page><CircularProgress color='primary' /></Page>
    return data?.authUser ? <Component /> : null
}

export const loginRequired = userCheckRequired(user => Boolean(user))
export const staffRequired = userCheckRequired(user => user.isTenantStaff)

export const checkForPermissions = (permissions: (keyof UserFragment['permissions'])[]) => userCheckRequired(user => {
    return permissions.every(permission => user?.permissions[permission] === true)
})
